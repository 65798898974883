export enum FlagOptionsEnum {
  enable_licensing_workflows = 'enable_licensing_workflows',
  test_flag = 'test_flag',
  open_selected_files = 'open_selected_files',
  new_exports = 'new_exports',
  show_pipeline_data_verifications = 'show_pipeline_data_verifications',
  facilities_mvp = 'facilities_mvp',
  async_psv_generation = 'async_psv_generation',
  org_settings_search_users = 'org_settings_search_users',
  rosteros = 'rosteros',
  enrollments = 'enrollments',
  rdb_users_organizations = 'rdb_users_organizations',
  rdb_providers = 'rdb_providers',
  flags_dashboard = 'flags_dashboard',
  all_org_pages = 'all_org_pages',
  new_psv_requirements = 'new_psv_requirements',
  payor_enrollment = 'payor_enrollment',
  inbound_outreach = 'inbound_outreach',
  wellspan_poc = 'wellspan_poc',
  pipeline_status_ui = 'pipeline_status_ui',
  clean_non_clean_configuration = 'clean_non_clean_configuration',
  product_support_to_jira_migration = 'product_support_to_jira_migration',
  provider_group_specialties = 'provider_group_specialties',
  parent_organization = 'parent_organization',
}

export type FlagOptions = keyof typeof FlagOptionsEnum;

export type FeatureFlagValues = {
  [FlagOptionsEnum.wellspan_poc]: FeatureFlagValue<{
    multiplePsvEnabled: boolean;
    showHospitalAffiliation: boolean;
    multipleNpdbReportsEnabled: boolean;
    hardCodedPsvCheckList: boolean;
    showSubcollectionAttachments: boolean;
  }>;
  [FlagOptionsEnum.pipeline_status_ui]: FeatureFlagValue<{}>;
  [FlagOptionsEnum.new_psv_requirements]: FeatureFlagValue<{
    isProgressionBlockingEnabled: boolean;
  }>;
};

type FeatureFlagValue<T> = {
  enabled: boolean;
  values: T;
};
